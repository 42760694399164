@import url('https://fonts.googleapis.com/css?family=Fira+Code|IBM+Plex+Sans&display=swap');
@import "./scss/react-toggle.scss";

body {
	font-family: 'IBM Plex Sans', sans-serif;
}

code {
	font-family: 'Fira Code', monospace;
}
